import React, { useEffect, useRef, useState, useCallback } from 'react';

const SwapToCoop = () => {
    return (
        <section>
        <iframe
        title="Vestige Widget"
        src="https://vestige.fi/widget/swap?asset_out=796425061"
        width="300" height='300'
        />
        </section>
    );
};

export default SwapToCoop;
