import React, { useEffect, useState } from 'react';
import './HomePage.css';
import Modal from '../../Controls/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setShowPrompt } from '../../applicationSlice';
import { Link } from 'react-router-dom';
import SwapComponent from '../../Controls/SwapComponent';
import { saveEmail } from '../../firebaseConfig';
import { Button, Snackbar, Alert } from '@mui/material';
import AssetInfoContainer from '../../Controls/AssetInfoContainer';
import CoopCoinData from '../../Controls/CoopCoinData';
function HomePage() {
  const dispatch = useDispatch();
  const application = useSelector(state=>state.application);
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);



useEffect(()=>{
  setModalOpen(application.showPrompt);
},[application.showPrompt])



const handleCloseErrorSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
      return;
  }
  setOpenErrorSnackbar(false);
};


  return (    
    <div className="home-container">
 

   <section>
    <iframe
title="Vestige Widget"
src="https://vestige.fi/widget/796425061/chart?currency=usd"
width="75%" height='350'
/>
</section>
<section>
<CoopCoinData />
</section>


            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseErrorSnackbar}>
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
  </div>
  );
}

export default HomePage;
