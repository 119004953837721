const TheReCoop = ()=>{
    return(
        <div class="iframe-container">
        <iframe width={'100%'} height={'1000'}
            src="https://www.youtube.com/@TheReCoop" 
            title="The ReCoop">
        </iframe>
    </div>
    )
}

export default TheReCoop