const CoopHair = ()=>{
    return(
        <div class="iframe-container">
        <iframe width={'100%'} height={'1000'}
            src="https://coop.hair" 
            title="Coop Hair">
        </iframe>
    </div>
    )
}

export default CoopHair