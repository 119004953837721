import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaUsers,FaYoutube , FaRoute, FaChevronDown, FaChevronUp,FaExchangeAlt  } from 'react-icons/fa';
import './Navbar.css';
import WalletConnector from '../WalletConnector';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="nav">
      <div className="hamburger-menu" onClick={toggleMenu}>
        {isOpen ? <FaChevronUp className="nav-icon" /> : <FaChevronDown className="nav-icon" />}
      </div>
      <ul className={`nav-list ${isOpen ? 'open' : ''}`}>
        <li>
          <NavLink to="/" exact="true" className="nav-link" activeclassname="active">
            <FaHome className="nav-icon" /> Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className="nav-link" activeclassname="active">
            <FaInfoCircle className="nav-icon" /> About
          </NavLink>
        </li>

        <li>
          <NavLink to="/roadmap" className="nav-link" activeclassname="active">
            <FaRoute className="nav-icon" /> Roadmap
          </NavLink>
        </li>
        <li>
          <NavLink to="/swap" className="nav-link" activeclassname="active">
            <FaExchangeAlt   className="nav-icon" /> Swap
          </NavLink>
        </li>
        <li>
                <NavLink to="/social" className="nav-link" activeclassname="active">
                    <FaUsers className="nav-icon" /> Social
                </NavLink>
            </li>
            <li>
    <NavLink to="/hair" className="nav-link" activeclassname="active">
         Coop.Hair
    </NavLink>
</li>
            <li>
    <NavLink to="https://www.youtube.com/@TheReCoop" className="nav-link" activeclassname="active">
    <FaYoutube   className="nav-icon" />
         The ReCoop
    </NavLink>
</li>
      </ul>
    </nav>
  );
};

export default Nav;
