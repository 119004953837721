import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const CoopCoinData = () => {
  const [assetData, setAssetData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-coop-coin-official.cloudfunctions.net/getAlgorandAssetData?assetId=796425061"
        );
        setAssetData(response.data);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div style={styles.loader}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error}</div>;

  const {
    name,
    ticker,
    asa_creator,
    asa_burned_supply,
    asa_circulating_supply,
    asa_supply,
    asa_priceData,
    algoPriceData,
    change1h,
    change24h,
    market_cap,
    tvl,
    tvl_stable,
    top_holders,
  } = assetData;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied to clipboard!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getChangeStyle = (change) => ({
    color: change > 0 ? "green" : "red",
  });

  return (
    <div style={styles.container}>
      <ToastContainer />
      <h1 style={styles.title}>
        {name} ({ticker})
      </h1>
      <p>
  <strong>Asset ID:</strong> {796425061}
  <button
    style={styles.copyButton}
    onClick={() => copyToClipboard("796425061")}
  >
    📋
  </button>
</p>

      {/* ASA Overview Section */}
      <div style={styles.supplyContainer}>
        <h2>🔢 Supply Overview</h2>
        <div style={styles.supplyCards}>
          <div style={styles.card}>
            <h3>
              🌍 Total Supply
            </h3>
            <p>{(Number(asa_supply) / 1_000_000).toLocaleString()} COOP</p>
          </div>
          <div style={styles.card}>
            <h3>
              🪙 Circulating Supply
            </h3>
            <p>{(Number(asa_circulating_supply) / 1_000_000).toLocaleString()} COOP</p>
            <p style={styles.caption}>
             <em>(Total Supply - Burned)</em>
            </p>
          </div>
          <div style={{ ...styles.card, ...styles.burnedCard }}>
            <h3>
              🔥 Burned Supply
            </h3>
            <p>{(Number(asa_burned_supply) / 1_000_000).toLocaleString()} COOP</p>
          </div>
        </div>
        <p>
          <strong>Creator ASA:</strong>{" "}
          <span style={styles.creator}>
            {asa_creator.slice(0, 5)}...{asa_creator.slice(-5)}
          </span>
          <button
            style={styles.copyButton}
            onClick={() => copyToClipboard(asa_creator)}
          >
            📋
          </button>
        </p>
      </div>

      {/* Price Data Section */}
      <div style={styles.dataContainer}>
        <h2>🪙 ASA Price Data</h2>
        <p><strong>USD:</strong> ${asa_priceData.USD.toFixed(4)}</p>
        <p><strong>EUR:</strong> €{asa_priceData.EUR.toFixed(4)}</p>
        <p><strong>GBP:</strong> £{asa_priceData.GBP.toFixed(4)}</p>
        <p><strong>BTC:</strong> {asa_priceData.BTC.toFixed(8)} BTC</p>

        <h2>💵 Algo Price Data</h2>
        <p><strong>USD:</strong> ${algoPriceData.USD.toFixed(4)}</p>
        <p><strong>EUR:</strong> €{algoPriceData.EUR.toFixed(4)}</p>
        <p><strong>GBP:</strong> £{algoPriceData.GBP.toFixed(4)}</p>
        <p><strong>BTC:</strong> {algoPriceData.BTC.toFixed(8)} BTC</p>
      </div>

      {/* Statistics Section */}
      <div>
        <h2>📈 Statistics</h2>
        <p>
          <strong>Change (1h):</strong>{" "}
          <span style={getChangeStyle(change1h)}>{change1h.toFixed(2)}%</span>
        </p>
        <p>
          <strong>Change (24h):</strong>{" "}
          <span style={getChangeStyle(change24h)}>{change24h.toFixed(2)}%</span>
        </p>
        <p><strong>Market Cap:</strong> {market_cap.toLocaleString()} ALGO</p>
        <p><strong>TVL:</strong> {tvl.toLocaleString()} ALGO</p>
        <p><strong>Stable TVL:</strong> {tvl_stable.toLocaleString()} ALGO</p>
      </div>

      {/* Top Holders Section */}
      <div style={styles.holdersContainer}>
        <h2>Top Holders</h2>
        <ul style={styles.holdersList}>
          {top_holders.map((holder, index) => (
            <li key={index} style={styles.holderItem}>
              <div>
                <span>
                  {holder.address.slice(0, 5)}...{holder.address.slice(-5)}
                </span>
                <button
                  style={styles.copyButton}
                  onClick={() => copyToClipboard(holder.address)}
                >
                  📋
                </button>
              </div>
              <span style={styles.balance}>
                {(Number(holder.balance) / 1_000_000).toLocaleString()} COOP
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.9)", // White with 90% opacity
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(10px)", // Optional: Adds a blur effect to the background
    color: "#333",

  },
  title: {
    textAlign: "center",
    color: "#333",
  },
  supplyContainer: {
    marginBottom: "10px",
    textAlign: "center",
  },
  supplyCards: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  card: {
    flex: "1",
    margin: "0 5px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#fff",
    textAlign: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  burnedCard: {
    backgroundColor: "#ffe6e6",
    borderColor: "#ff4d4d",
  },
  creator: {
    fontWeight: "bold",
    marginLeft: "5px",
  },
  dataContainer: {
    lineHeight: "1.5",
  },
  holdersContainer: {
    marginTop: "20px",
  },
  holdersList: {
    listStyleType: "none",
    padding: 0,
  },
  holderItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    borderBottom: "1px solid #ddd",
  },
  copyButton: {
    marginLeft: "10px",
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
  },
  balance: {
    fontWeight: "bold",
  },
  loader: {
    textAlign: "center",
    padding: "20px",
    fontSize: "18px",
    color: "#555",
  },
  error: {
    textAlign: "center",
    padding: "20px",
    fontSize: "18px",
    color: "red",
  },
  caption: {
    fontSize: "0.9em",
    color: "#666",
    marginTop: "2px",
  },
};

export default CoopCoinData;
