const Wecoop = ()=>{
    return(
        <div class="iframe-container">
        <iframe width={'100%'} height={'1000'}
            src="https://wecoop.xyz/feed?activeFeed=coinFeed&activeAssetId=796425061" 
            title="Coop Coin Social Media Feed">
        </iframe>
    </div>
    )
}

export default Wecoop